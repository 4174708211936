import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Project from '../components/Project'
import Skills from '../components/Skills'
import Offers from '../components/Offers'
import { 
  Heading,
  Text,
  Link,
} from "@chakra-ui/react"

const HomeIndex = () => {
  const siteTitle = 'Marcel Hurler'
  const siteDescription = 'Software Entwickler und KI Experte'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="one">
          <header className="major">
              <Heading>
                Über mich
              </Heading>
          </header>
          <br />
          <Text>
          Hi, ich bin Marcel und arbeite als Freelancer im Bereich Machine Learning mit Schwerpunkt Computer Vision und Natural Language Processing.
          Neben meiner Vorliebe zum Computer bin ich begeisterter Sportler.
          Ich spiel aktiv Tennis, gehe im Winter gerne mit Freunden Snowboard fahren und freie Zeit im Sommer verbringe ich am liebsten beim Surfen in den Wellen an der Atlantikküste in Frankreich.
          </Text>
        </section>

        <section id="two">
          <Offers />
        </section>

        <section id="three" >
          <Skills />
        </section>

        <section id="four">
          <header>
              <Heading>Biografie</Heading>
          </header>
            <Project header="Learning Free Surface Flow with Physics informed Neural Networks"
                     content="In meiner Masterarbeit untersuchte ich Lösungen der Flachwassergleichungen mithilfe von Physik Informierten Neuronalen Netzen (PINNs). Die Flachwassergleichungen sind hyperbolische partielle Differentialgleichungen und finden Anwendung z.B. bei Dammbruch und Tsunami Simulationen. Mit PINNs lassen sich Differentialgleichungen lernen, ohne dass a-priori unbekannte Informationen während dem Training verwendet werden. Ausgabe ist ein Modell welches im Gegensatz zu klassischen diskretisierten Lösungen, ohne Interpolation, Ergebnisse an jeder Ort-Zeit Koordinate im gelernten Gebiet liefert. Die grundlegende Idee hierbei ist es Ausgaben des Modells direkt in die Gleichungen einzusetzen und Abweichungen in der Loss Funktion zu bestrafen. In meiner Arbeit stelle ich Lösungen für drei Anfangswertprobleme dar und untersuche die Auswirkung diverser Optimierungsmethoden."
                     project_date="Jul 2020 - Jan 2021"
                     article_date="06 Jul 2021"
                     min_read="2 min"
                     author="Marcel Hurler"
                     tools={['python', 'bash']}
            />

            <Project header="Wissenschaftliche Hilfskraft in der Abteilung Scientific Computing"
                     content={["Während dem Studium war ich als Hiwi in der Abteilung SC am IPVS an der Universität Stuttgart angestellt. Dabei hab ich an einem hochparallelen ", <Link href="https://github.com/SGpp/DisCoTec" isExternal>C++ Framework</Link>, " für die Dünngitter Kombinationstechnik gearbeitet. Meine Aufgabe war es eine zusätzliche Parallelisierungsebene, die sich über die Grenzen von Hochleistungsrechnern streckt, zu implementieren und zu untersuchen. Hier konnte ich viel Erfahrung in C++, dem Arbeiten an Hochleistungsrechnern sowie dem verteilten Rechnen mit MPI und TCP-Sockets sammeln."]}
                     project_date="Jun 2017 - Mar 2020"
                     article_date="06 Jul 2021"
                     min_read="2 min"
                     author="Marcel Hurler"
                     tools={['python', 'bash']}
            />

            <Project header="Speach Emotion Recognition"
                     content={["Während eines Projekts im Kurs Deep Learning for Natural Speech and Language Processing an der Universität Stuttgart konnte ich erste Praktische Erfahrungen im Bereich NLP erfahren. Ziel war es aus aufbereiteten Sprachaufnahmen die Stimmung des Sprechers abzuschätzen. Dabei habe ich sowohl ein damaliges State-of-the-Art CNN-basiertes als auch ein LSTM-CNN-basiertes Modell implementiert und ausgewertet. Das CNN basierte Schnitt besser ab. Meinen Code findest du in meinem alten ", <Link href="https://github.com/mhurler123/SpeechToEmotion" isExternal>github repository</Link>, "."]}
                     project_date="Jan 2020 - Feb 2020"
                     article_date=""
                     min_read="1 min"
                     author="Marcel Hurler"
                     tools={[]}
            />

            <Project header="Masterstudium Universität Stuttgart"
                     content="Vertiefungsmodule in den Bereichen wissenschaftliches Rechnen und Intelligente Systeme."
                     project_date="2017 - 2021"
                     article_date=""
                     min_read=""
                     author=""
                     tools={[]}
            />

            <Project header="Bachelorstudium Universität Stuttgart"
                     content=""
                     project_date="2011 - 2017"
                     article_date=""
                     min_read=""
                     author=""
                     tools={[]}
            />
        </section>

      </div>
    </Layout>
  )
}

export default HomeIndex
