import React from 'react';
import {
  Box,
  Center,
  Circle,
  Heading,
  Text,
  Stack,
  useColorModeValue,
  Collapse,
} from '@chakra-ui/react';
//import ShowMoreText from 'react-show-more-text';
import {FaChevronDown, FaChevronUp} from 'react-icons/fa'



const Project = (props) => {
    const [show, setShow] = React.useState(false);

    const handleToggle = () => setShow(!show);

    var collapseButton;
    var criterion = props.content;
    if (typeof props.content == 'string') {
      criterion = props.content.length
    } else {
      criterion = Object.values(props.content)[0].length
    }
    console.log(criterion)
    if (criterion >= 100)  {
      collapseButton =  (
              <Center>
                <Circle
                  size="30px"
                  onClick={handleToggle}
                  as="button"
                  mt="1rem"
                  boxShadow="base"
                >
                  {show ? <FaChevronUp /> : <FaChevronDown />}
                </Circle>
              </Center>
      )
    } else {
      collapseButton =  ("")
    }

    return (
      <Center py={6}>
        <Box
          w={'100%'}
          bg={useColorModeValue('white', 'gray.900')}
          boxShadow={'2xl'}
          rounded={'md'}
          p={6}
          paddingBottom={2}
          overflow={'hidden'}
        >
          <Stack>
            <Heading
              color={useColorModeValue('gray.700', 'white')}
              fontSize={'2xl'}
              fontFamily={'body'}
            >
              {props.header}
            </Heading>
            <Text
              color={'green.500'}
              textTransform={'uppercase'}
              fontWeight={800}
              fontSize={'sm'}
              letterSpacing={1.1}
            >
              {props.project_date}
            </Text>
              <>
              <Collapse startingHeight={45} in={show} animateOpacity={true} >
                {props.content}
              </Collapse>
              {collapseButton}
              </>
          </Stack>
          {/*
        <Stack mt={6} direction={'row'} spacing={4} align={'center'}>
          <Avatar
            src={'https://avatars0.githubusercontent.com/u/1164541?v=4'}
            alt={'Author'}
          />
          <Stack direction={'column'} spacing={0} fontSize={'sm'}>
            <Text fontWeight={600}>{props.author}</Text>
            <Text color={'gray.500'}>{props.article_date} · {props.min_read}</Text>
          </Stack>
        </Stack>
        */}
        </Box>
      </Center>
    )
  };

export default Project;