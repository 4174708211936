import React from 'react'
import {
  Heading,
  Text,
  Table,
  Tbody,
  Td,
  Tr,
  SimpleGrid,
  Center,
} from '@chakra-ui/react'
import {CheckIcon} from  '@chakra-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faCubes, faPuzzlePiece, faCogs} from '@fortawesome/free-solid-svg-icons'



const fontsize = "20px"
const iconColor = ""

const Offers = (props) => {
  return (
    <div id="skills">
      <header className="major">
        <Heading>Meine Leistungen</Heading>
      </header>
      <br></br>
      <br></br>
      <Center>
        <SimpleGrid columns={[1, 1, 2]} spacingX={"10vw"} spacingY={10}>
          {/*<Text color="#787878" fontSize={fontsize}><CheckIcon color="green"/>&nbsp;&nbsp;Beratung</Text>*/}
          <Text color="#787878" fontSize={fontsize}>
            <FontAwesomeIcon icon={faComments} color={iconColor} />
            &nbsp;&nbsp;Beratung
          </Text>
          {/*<Text color="#787878" fontSize={fontsize}><CheckIcon color="green"/>&nbsp;&nbsp;Modellauswahl</Text>*/}
          <Text color="#787878" fontSize={fontsize}>
            <FontAwesomeIcon icon={faCubes} color={iconColor} />
            &nbsp;&nbsp;Modellauswahl
          </Text>
          {/*<Text color="#787878" fontSize={fontsize}><CheckIcon color="green"/>&nbsp;&nbsp;Training und Evaluierung</Text>*/}
          <Text color="#787878" fontSize={fontsize}>
            <FontAwesomeIcon icon={faCogs} color={iconColor} />
            &nbsp;&nbsp;Training und Evaluierung
          </Text>
          {/*<Text color="#787878" fontSize={fontsize}><CheckIcon color="green"/>&nbsp;&nbsp;Integration</Text>*/}
          <Text color="#787878" fontSize={fontsize}>
            <FontAwesomeIcon icon={faPuzzlePiece} color={iconColor} />
            &nbsp;&nbsp;Integration
          </Text>
        </SimpleGrid>
      </Center>
    </div>
  )
}

export default Offers