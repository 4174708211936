import React from 'react'
import {
  Box,
  Center,
  Heading,
  Text,
  Table,
  Tbody,
  Td,
  Tr,
  Tfoot,
  Th,
  Avatar,
  Image,
  Flex,
} from '@chakra-ui/react'
import gitIcon from '../assets/images/Git_icon.svg'
import pythonIcon from '../assets/images/python-seeklogo.com.svg'
import pytorchIcon from '../assets/images/PyTorch_logo_icon.svg'
import ccIcon from '../assets/images/ISO_C++_Logo.svg'
import bashIcon from '../assets/images/Bash_Logo_Colored.svg'
import atlassianIcon from '../assets/images/atlassian.svg'
import ProgressBar from '@ramonak/react-progress-bar'

const ProgressBarStyle = {
  height: '7px',
  width: '25vw',
  bgColor: 'green',
  baseBgColor: '#e0e0e0de',
  labelSize: '0px',
}

const fontsize = '15px'

class Skills extends React.Component {
  constructor() {
    super()
    this.state = {
      cxx: 0,
      python: 0,
      pytorch: 0,
      bash: 0,
      git: 0,
      atlassian: 0,
    }
  }

  myBar(percentage) {
    return (
      <ProgressBar
        width={ProgressBarStyle.width}
        height={ProgressBarStyle.height}
        bgColor={ProgressBarStyle.bgColor}
        baseBgColor={ProgressBarStyle.baseBgColor}
        labelSize={ProgressBarStyle.labelSize}
        completed={percentage}
      />
    )
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        cxx: 85,
        python: 80,
        pytorch: 70,
        bash: 60,
        git: 80,
        atlassian: 50,
      })
    }, 1000)
  }

  render() {
    return (
      <div id="skills">
        <header className="major">
          <Heading>Kenntnisse</Heading>
        </header>
        <br></br>
        <Flex marginBottom="-10">
        <Center>
          <Table variant="unstyled">
            <Tbody>
              <Tr>
                <Th verticalAlign="middle">
                  <Box>
                    <Text fontSize={fontsize} color="#787878" align="right">
                      C/C++
                    </Text>
                  </Box>
                </Th>
                <Th>
                  <Box boxSize={["5vw", "3vw", "3vw", "2vw", "1.3vw",]} maxHeight="30px">
                    <Image
                      src={ccIcon}
                    ></Image>
                  </Box>
                </Th>
                <Th verticalAlign="middle">{this.myBar(this.state.cxx)}</Th>
              </Tr>
              <Tr>
                <Th verticalAlign="middle">
                  <Box>
                    <Text fontSize={fontsize} color="#787878" align="right">
                      Python
                    </Text>
                  </Box>
                </Th>
                <Th>
                  <Box boxSize={["5vw", "3vw", "3vw", "2vw", "1.3vw",]} maxHeight="30px">
                  <Image
                    src={pythonIcon}
                  ></Image>
                  </Box>
                </Th>
                <Th verticalAlign="middle">{this.myBar(this.state.python)}</Th>
              </Tr>
              <Tr>
                <Th verticalAlign="middle">
                  <Box>
                    <Text fontSize={fontsize} color="#787878" align="right">
                      Pytorch
                    </Text>
                  </Box>
                </Th>
                <Th>
                  <Box boxSize={["5vw", "3vw", "3vw", "2vw", "1.3vw",]} maxHeight="30px">
                  <Image
                    src={pytorchIcon}
                  ></Image>
                  </Box>
                </Th>
                <Th verticalAlign="middle">{this.myBar(this.state.pytorch)}</Th>
              </Tr>
              <Tr>
                <Th verticalAlign="middle">
                  <Box>
                    <Text fontSize={fontsize} color="#787878" align="right">
                      Bash
                    </Text>
                  </Box>
                </Th>
                <Th>
                  <Box boxSize={["5vw", "3vw", "3vw", "2vw", "1.3vw",]} maxHeight="30px">
                  <Image
                    src={bashIcon}
                  ></Image>
                  </Box>
                </Th>
                <Th verticalAlign="middle">{this.myBar(this.state.bash)}</Th>
              </Tr>
              <Tr>
                <Th verticalAlign="middle">
                  <Box>
                    <Text fontSize={fontsize} color="#787878" align="right">
                      Git
                    </Text>
                  </Box>
                </Th>
                <Th>
                  <Box boxSize={["5vw", "3vw", "3vw", "2vw", "1.3vw",]} maxHeight="30px">
                  <Image
                    src={gitIcon}
                  ></Image>
                  </Box>
                </Th>
                <Th verticalAlign="middle">{this.myBar(this.state.git)}</Th>
              </Tr>
              <Tr>
                <Th verticalAlign="middle">
                  <Box>
                    <Text fontSize={fontsize} color="#787878" align="right">
                      Atlassian
                    </Text>
                  </Box>
                </Th>
                <Th verticalAlign="middle">
                  <Box boxSize={["5vw", "3vw", "3vw", "2vw", "1.3vw",]} maxHeight="30px">
                  <Image
                    src={atlassianIcon}
                  ></Image>
                  </Box>
                </Th>
                <Th verticalAlign="middle">{this.myBar(this.state.atlassian)}</Th>
              </Tr>
            </Tbody>
          </Table>
        </Center>
          </Flex>
      </div>
    )
  }
}

export default Skills
